exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/blog/{MarkdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-slides-index-tsx": () => import("./../../../src/pages/slides/index.tsx" /* webpackChunkName: "component---src-pages-slides-index-tsx" */),
  "component---src-pages-talks-index-tsx": () => import("./../../../src/pages/talks/index.tsx" /* webpackChunkName: "component---src-pages-talks-index-tsx" */),
  "component---src-pages-talks-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/talks/{MarkdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-talks-markdown-remark-frontmatter-slug-tsx" */)
}

